/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { PropertyCarousel } from '@ten-x/fe-lib-ui2020'
import _get from 'lodash/get'

const PropertyCarouselContainer = ({
  assetsList,
  responsiveBreakPoints,
  savedAssets,
  toggleSaveAsset,
  personalizationType,
  featureFlags,
}) => {
  const buyNowFlag = _get(featureFlags, 'fe.cre.buy-now', false)
  const placards = assetsList.map((asset) => {
    return {
      ...asset,
      buyNowEnabled: buyNowFlag && asset.isBuyNowEnabled,
    }
  })

  return (
    <PropertyCarousel
      placards={placards}
      responsiveBreakPoints={responsiveBreakPoints}
      savedAssets={savedAssets}
      toggleSaveAsset={toggleSaveAsset}
      personalizationType={personalizationType}
    />
  )
}

PropertyCarouselContainer.propTypes = {
  assetsList: PropTypes.any,
  responsiveBreakPoints: PropTypes.shape({
    breakpoint: PropTypes.number,
    settings: PropTypes.shape({
      slidesToShow: PropTypes.number,
      slidesToScroll: PropTypes.number,
    }),
  }),
  savedAssets: PropTypes.any,
  toggleSaveAsset: PropTypes.any,
  personalizationType: PropTypes.string,
  featureFlags: PropTypes.object,
}

export default PropertyCarouselContainer
